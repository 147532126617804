import { ApolloClient, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';

import {
  customUpdateCustomerPassword,
  queryAddItemToCart,
  queryCreateCustomerDirection,
  queryDeleteCustomerDirection,
  queryGetCustomerInfo,
  queryLoginActivate,
  queryLoginCustomer,
  queryLoginRequest,
  queryLoginReset,
  queryNewCart,
  queryReadCart,
  queryRegisterCustomer,
  queryRemoveItemFromCart,
  queryUpdateCustomerDirection,
  queryUpdateCustomerInfo,
  queryUpdateItemFromCart,
} from '@/lib/graphql/queries';

type TypeLineItem = {
  variantId: string;
  quantity: number;
};

const client = new ApolloClient({
  uri: `https://${process.env.SHOPIFY_STORE}/admin/api/${process.env.SHOPIFY_VERSION}/graphql.json`,
  headers: {
    'X-Shopify-Access-Token': process.env.SHOPIFY_ACCESS_TOKEN || '',
    Accept: 'application/graphql',
  },
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

const storeFront = new ApolloClient({
  uri: `https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE}/api/${process.env.NEXT_PUBLIC_SHOPIFY_VERSION}/graphql.json`,
  headers: {
    'X-Shopify-Storefront-Access-Token':
      process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN || '',
    Accept: 'application/graphql',
  },
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export async function GetOrderInfo(id) {
  const { data } = await client.query({
    query: gql`
    {
      node(id: "gid://shopify/Order/${id}") {
        ... on Order {
          email
          name
          phone
          shippingAddress {
            firstName
            lastName
            address1
            address2
            province
            zip
            city
            countryCodeV2
          }
          lineItems(first: 50) {
            nodes {
              name
              currentQuantity
              image {
                src
                altText
              }
              originalTotal
              totalDiscount
              variant {
                displayName
                product {
                  id
                  title
                }
              }
            }
          }
          totalPrice
          discountCodes
          totalDiscounts
          totalTax
        }
      }
    }
    `,
  });

  return data;
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function GetAllProducts() {
  const getProducts = () =>
    client.query({
      query: gql`
        {
          products(first: 18) {
            edges {
              node {
                id
                title
                handle
                description
                tags
                metafields(first: 4) {
                  nodes {
                    key
                    value
                  }
                }
                variants(first: 1) {
                  edges {
                    node {
                      id
                      compareAtPrice
                      price
                    }
                  }
                }
                featuredImage {
                  url
                  width
                  height
                  altText
                }
                priceRangeV2 {
                  maxVariantPrice {
                    amount
                  }
                  minVariantPrice {
                    amount
                  }
                }
                totalInventory
                totalVariants

                media(first: 5) {
                  edges {
                    node {
                      ... on MediaImage {
                        id
                        image {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    });

  let { data, errors } = await getProducts();

  while (errors && errors.length > 0) {
    await timeout(9000);
    const { data: newData, errors: newErrors } = await getProducts();
    data = newData;
    errors = newErrors;
  }

  return data;
}

export async function GetProduct(id) {
  const { data } = await client.query({
    query: gql`
      {
        product(id: "${id}") {

              id
              title
              handle
              description
              tags
              metafields(first: 4) {
                nodes {
                  key
                  value
                }
              }
              variants(first: 1) {
                edges {
                  node {
                    id
                    compareAtPrice
                    price
                  }
                }
              }
              featuredImage {
                url
                width
                height
                altText
              }
              priceRangeV2 {
                maxVariantPrice {
                  amount
                }
                minVariantPrice {
                  amount
                }
              }
              totalInventory
              totalVariants

              media(first: 5) {
                edges {
                  node {
                    ... on MediaImage {
                      id
                      image {
                        src
                      }
                    }
                  }
                }
              }
        }
      }
    `,
  });

  return data;
}

export async function createDraftOrder(
  carts: { id: string; cantidad: number }[]
) {
  const temp: TypeLineItem[] = [];

  carts.map((item) => {
    temp.push({
      variantId: item.id,
      quantity: item.cantidad,
    });
  });

  const { data } = await client.mutate({
    mutation: gql`
      mutation draftOrderCreate($input: DraftOrderInput!) {
        draftOrderCreate(input: $input) {
          draftOrder {
            id
            invoiceUrl
            totalTax
            totalPrice
          }
        }
      }
    `,
    variables: {
      input: {
        note: 'Pedido',
        email: '',
        lineItems: temp,
      },
    },
  });

  return data;
}

export async function updateDraftOrder(
  orderId: string,
  carts: { id: string; cantidad: number }[],
  descuento: {
    descripcion: string;
    valor: string;
    percentage: number;
    amount: number;
  }
) {
  const temp: TypeLineItem[] = [];

  carts.map((item) => {
    temp.push({
      variantId: item.id,
      quantity: item.cantidad,
    });
  });

  const { data } = await client.mutate({
    mutation: gql`
      mutation draftOrderUpdate($id: ID!, $input: DraftOrderInput!) {
        draftOrderUpdate(id: $id, input: $input) {
          draftOrder {
            id
            invoiceUrl
            totalTax
            totalPrice
            subtotalPrice
            totalTax
            appliedDiscount {
              amountV2 {
                amount
              }
            }
          }
        }
      }
    `,
    variables: {
      id: orderId,
      input: {
        note: 'Pedido',
        email: '',
        lineItems: temp,
        appliedDiscount: {
          description: descuento.descripcion,
          value:
            descuento.percentage !== 0
              ? descuento.percentage
              : descuento.amount,
          valueType: descuento.percentage !== 0 ? 'PERCENTAGE' : 'FIXED_AMOUNT',
        },
      },
    },
  });

  return data;
}

export async function OrderTextGift(orderId: string, text: string) {
  const { data } = await client.mutate({
    mutation: gql`
    mutation {
      orderUpdate(input: {id: "gid://shopify/Order/${orderId}", metafields: {key: "gift_text", value: "${text}", namespace: "custom"}}) {
        order {
          id
        }
      }
    }
    `,
  });

  return data;
}
export async function readDraftOrder(orderId: string) {
  const { data } = await client.query({
    query: gql`
    {
      draftOrder(id: "${orderId}") {
        name
        invoiceUrl
        lineItems(first: 10) {
          edges {
            node {
              id
              name
              quantity
              image {
                url
              }
            }
          }
        }
      }
    }
    `,
  });

  return data;
}

export async function GetAllDiscount() {
  const { data } = await client.query({
    query: gql`
      {
        priceRules(first: 40) {
          nodes {
            discountCodes(first: 20) {
              nodes {
                code
              }
            }
            value {
              ... on PriceRulePercentValue {
                percentage
              }
              ... on PriceRuleFixedAmountValue {
                amount
              }
            }
            summary
            title
            usageCount
            usageLimit
            status
          }
        }
      }
    `,
  });

  return data;
}

export const newCart = async () =>
  await storeFront.mutate({ mutation: queryNewCart() });

export const ReadCart = async (cartId: string) =>
  await storeFront.query({ query: queryReadCart(cartId) });

export const addItemToCart = async (
  cartId: string,
  itemId: string,
  quantity: number
) =>
  await storeFront.mutate({
    mutation: queryAddItemToCart(cartId, itemId, quantity),
  });

export const RemoveItemFromCart = async (cartId: string, lineId: string) =>
  await storeFront.mutate({
    mutation: queryRemoveItemFromCart(cartId, lineId),
  });

export const UpdateItemFromCart = async (
  cartId: string,
  lineId: string,
  itemId: string,
  quantity: number
) =>
  await storeFront.mutate({
    mutation: queryUpdateItemFromCart(cartId, lineId, itemId, quantity),
  });

export const RegisterCustomer = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  acceptsMarketing: boolean
) =>
  await storeFront.mutate({
    mutation: queryRegisterCustomer(
      firstName,
      lastName,
      email,
      password,
      acceptsMarketing
    ),
  });

export const LoginCustomer = async (email: string, password: string) =>
  await storeFront.mutate({
    mutation: queryLoginCustomer(email, password),
  });

export const LoginRequest = async (email: string) =>
  await storeFront.mutate({
    mutation: queryLoginRequest(email),
  });

export const LoginReset = async (id: string, token: string, password: string) =>
  await storeFront.mutate({
    mutation: queryLoginReset(id, token, password),
  });

export const LoginActivate = async (
  id: string,
  token: string,
  password: string
) =>
  await storeFront.mutate({
    mutation: queryLoginActivate(id, token, password),
  });

export const GetCustomerInfo = async (token: string) =>
  await storeFront.mutate({
    mutation: queryGetCustomerInfo(token),
  });

export const UpdateCustomerInfo = async (
  token: string,
  acceptsMarketing: boolean,
  email: string,
  firstName: string,
  lastName: string,
  phone: string
) =>
  await storeFront.mutate({
    mutation: queryUpdateCustomerInfo(
      token,
      acceptsMarketing,
      email,
      firstName,
      lastName,
      phone
    ),
  });

export const UpdateCustomerPassword = async (
  token: string,
  acceptsMarketing: boolean,
  email: string,
  firstName: string,
  lastName: string
) =>
  await storeFront.mutate({
    mutation: queryUpdateCustomerInfo(
      token,
      acceptsMarketing,
      email,
      firstName,
      lastName
    ),
  });

export const CustomUpdateCustomerPassword = async (
  token: string,
  password: string
) =>
  await storeFront.mutate({
    mutation: customUpdateCustomerPassword(token, password),
  });

export const UpdateCustomerDirection = async (
  directionID: string,
  token: string,
  address1: string,
  address2: string,
  city: string,
  province: string,
  phone: string
) =>
  await storeFront.mutate({
    mutation: queryUpdateCustomerDirection(
      directionID,
      token,
      address1,
      address2,
      city,
      province,
      phone
    ),
  });

export const CreateCustomerDirection = async (
  token: string,
  address1: string,
  address2: string,
  city: string,
  province: string,
  phone: string
) =>
  await storeFront.mutate({
    mutation: queryCreateCustomerDirection(
      token,
      address1,
      address2,
      city,
      province,
      phone
    ),
  });

export const DeleteCustomerDirection = async (
  directionID: string,
  token: string
) =>
  await storeFront.mutate({
    mutation: queryDeleteCustomerDirection(directionID, token),
  });
