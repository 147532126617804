import { debug, isProduction } from '@/utils';

export const debugGtm = (debugMsg: string, data: any): void => {
  if (!isProduction()) debug(`${debugMsg}: `, data);
};

export const gtmPush = (data: any): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  window.dataLayer?.push(data);

  debugGtm('GTM PUSH', data);
};

export const getFirstLetters = (str: string): string =>
  str
    .split(' ')
    .map((word) => word[0].toUpperCase())
    .join('');
