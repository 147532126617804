import { gql } from '@apollo/client';

export const queryNewCart = () => gql`
  mutation {
    cartCreate(input: {}) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        lines(first: 20) {
          edges {
            node {
              id
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                    amount
                  }
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;

export const queryReadCart = (cartId: string) => gql`
  query {
    cart(id: "${cartId}") {
      id
      createdAt
      updatedAt
      checkoutUrl
      lines(first: 50) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                price {
                    amount 
                }
              }
            }
          }
        }
      }
      attributes {
        key
        value
      }
      cost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        checkoutChargeAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
    }
  }
`;

export const queryAddItemToCart = (
  cartId: string,
  itemId: string,
  quantity: number
) => gql`
  mutation {
    cartLinesAdd(
      cartId: "${cartId}"
      lines: [
        {
          quantity: ${quantity}
          merchandiseId: "${itemId.startsWith("gid")? itemId : `gid://shopify/ProductVariant/${itemId}` }"
        }
      ]
    ) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        lines(first: 50) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                      amount 
                  }
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const queryRemoveItemFromCart = (cartId: string, lineId: string) => gql`
  mutation {
    cartLinesRemove(
      cartId: "${cartId}",
      lineIds: ["${lineId}"]
    ) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        lines(first: 50) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                      amount 
                  }
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const queryUpdateItemFromCart = (
  cartId: string,
  lineId: string,
  itemId: string,
  quantity: number
) => gql`
  mutation {
    cartLinesUpdate(
      cartId: "${cartId}",
      lines: {
        id: "${lineId}"
        merchandiseId: "${itemId.startsWith("gid")? itemId : `gid://shopify/ProductVariant/${itemId}` }",
        quantity: ${quantity},
      }
    ) {
      cart {
        id
        createdAt
        updatedAt
        checkoutUrl
        lines(first: 50) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                    amount 
                }
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
