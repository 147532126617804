import Script from 'next/script';

import { store } from '@/lib/store';
import { Provider } from 'react-redux';

import { cookies } from '@/lib/utils/cookies';

import 'styles/globals.css';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import * as React from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

/**
 * Article
 *
 *  TODO: Meta Robots, Layout type
 */

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function App({ Component, pageProps: { ...pageProps } }) {
	const router = useRouter();
	const queryClientRef = React.useRef<QueryClient>();
	if (!queryClientRef.current) {
		queryClientRef.current = new QueryClient();
	}

	useEffect(() => {
		const actual = localStorage.getItem('url') || '';
		if (actual !== router.asPath) {
			localStorage.setItem('prevurl', actual);
			localStorage.setItem('url', router.asPath);
			cookies('VirtualPage', router.asPath, actual);
		}
	}, [router.asPath]);

	return (
		<QueryClientProvider client={queryClientRef.current}>
			<Hydrate state={pageProps.dehydratedState}>
				<Provider store={store}>
					<Script defer type="text/javascript" id="accesitSctp" src="https://cdn.accesit.eu/navidul.js" />
					{/* <Script
						id="bim-snippet"
						src="https://cwcentribot.centribal.com/app/chat?key=8b56800ce3904715a4ee18991f4f0e16.bc8b25604bc8404181c24be859878624"
					/> */}

					<Script id="consent-mode" strategy="afterInteractive">
						{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          // Default ad_storage to 'denied'.
          gtag('consent', 'default', {
			"ad_storage": "denied",
			"ad_user_data": "denied",
			"ad_personalization": "denied",
			"analytics_storage": "denied",
          	'wait_for_update': 500
          });
        `}
					</Script>
					<Script id="google-tag-manager" strategy="afterInteractive">
						{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
					</Script>
					<Script
						src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
						id="cookie-control"
						strategy="lazyOnload"
						onLoad={() => {
							const config = {
								apiKey: process.env.NEXT_PUBLIC_COOKIE_CONTROL_API_KEY,
								product: process.env.NEXT_PUBLIC_COOKIE_CONTROL_PRODUCT,
								initialState: 'notify',
								notifyDismissButton: false,
								setInnerHTML: true,
								locale: document.querySelector('html')?.lang,
								branding: {
									fontFamily: 'Plus Jakarta Sans Variable',
									backgroundColor: '#000000AA',
									fontColor: '#FFF',
									notifyFontColor: '#FFF',
									acceptBackground: '#FF594C',
									rejectBackground: '#FF594C',
									toggleBackground: '#FFF',
									toggleText: '#444',
									toggleColor: '#444',
									acceptText: '#FFF',
									rejectText: '#FFF',
									removeAbout: true,
								},
								text: {
									title: 'Información acerca de las cookies utilizadas',
									intro:
										'Te informamos que en este sitio web https://www.navidul.es, titularidad de CAMPOFRIO FOOD GROUP S.A.U., se utilizan cookies propias técnicas que permiten el acceso a la navegación y a los servicios que se ofrecen en el sitio web, y cookies de terceros que, si lo autorizas: analizarán tu navegación con fines estadísticos y te mostrarán información comercial relacionada con tus hábitos de navegación y consumo. Para ello, únicamente se obtendrán datos de navegación.',
									necessaryTitle: 'Cookies técnicas',
									necessaryDescription:
										'Su objetivo principal es permitir el acceso a los servicios de la web solicitados por el usuario. Las cookies técnicas incluyen aquellas que el editor utiliza para permitir la gestión y operativa de la página web y habilitar sus funciones y servicios. Por ello, esta categoría de cookies debe permanecer siempre activa.',
									on: 'SI',
									off: 'NO',
									notifyTitle:
										'Su elección con respecto a las cookies en este sitio.',
									notifyDescription:
										'En este sitio web se utilizan cookies propias técnicas que permiten el acceso a la navegación y a los servicios que se ofrecen en el sitio web, y cookies de terceros que, si lo autorizas: analizarán tu navegación con fines estadísticos y te mostrarán información comercial relacionada con tus hábitos de navegación y consumo. Para ello, únicamente se obtendrán datos de navegación.<br><br>Puedes aceptar el uso de todas las cookies pulsando el botón "Aceptar" o <u style=" cursor: pointer;" onclick="$(\'.ccc-notify-link\').trigger(\'click\')">configurar o rechazar su uso pulsando en el botón "Configurar Cookies"</u>. Para conocer más información sobre las cookies que utilizamos o cómo eliminarlas, visita nuestra <a href="https://www.navidul.es/es/politicacookies">Política de cookies</a>.',
									accept: 'Aceptar y cerrar',
									acceptSettings: 'Aceptar cookies',
									reject: 'Rechazar',
									rejectSettings: 'Rechazar cookies',
									settings: 'Configurar cookies',
									showVendors: 'Mostrar proveedores dentro de esta categoría',
									readMore: 'Leer más',
								},
								optionalCookies: [
									{
										name: 'analytics',
										label: 'Cookies analíticas',
										description:
											'Permiten reconocer y contabilizar el número de visitantes de nuestro sitio web, así como ver cómo navegan por él y lo utilizan. Ayudan a mejorar el funcionamiento de nuestro sitio web.',
										cookies: [
											'_ga',
											'_gid',
											'_gat',
											'__utma',
											'__utmt',
											'__utmb',
											'__utmc',
											'__utmz',
											'__utmv',
										],

										onAccept: function () {
											cookies('analytics_consent_given');
										},
										onRevoke: function () {
											cookies('analytics_consent_given');
										},
									},
									{
										name: 'behaviouralAdvertising',
										label: 'Cookies de publicidad comportamental',
										description:
											'Las cookies de publicidad comportamental almacenan información sobre el comportamiento de los usuarios obtenida a través de sus hábitos de navegación. Estos datos nos permiten desarrollar un perfil específico y mostrarte publicidad en función de sus gustos e intereses.',
										cookies: [
											'_fbp',
											'_gcl_au',
											'_gcl_aw',
											'_gcl_dc',
											'_gac_UA-XXXXXXXX-X',
										],
										vendors: [
											{
												name: 'Facebook',
												url: 'https://www.facebook.com/policies/cookies',
												description: 'Facebook Pixel',
											},
											{
												name: 'Google',
												url: 'https://policies.google.com/technologies/types?hl=en-US',
												description: 'Google Ads',
											},
										],
										onAccept: function () {
											cookies('behavioural_advertising_consent_given');
										},
										onRevoke: function () {
											cookies('behavioural_advertising_consent_given');
										},
									},
								],
								position: 'LEFT',
								theme: 'DARK',
								closeStyle: 'label',
								statement: {
									description:
										'Para conocer más información sobre las cookies que utilizamos, visita nuestra ',
									name: 'Política de Cookies',
									url: 'https://www.navidul.es/es/politicacookies',
									updated: '25/08/2021',
								},
							};

							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							CookieControl.load(config);
						}}
					/>
					<Component {...pageProps} />

				</Provider>
			</Hydrate>
		</QueryClientProvider >
	);
};
export default App;
