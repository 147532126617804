import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from '@/utils';

export interface ORDERS {
  data: {
    draftOrder: {
      name: string;
      invoiceUrl: string;
      lineItems: {
        edges: [
          {
            node: {
              id: string;
              name: string;
              originalTotal: number;
              title: string;
              quantity: number;
              image: {
                src: string;
              };
            };
          }
        ];
      };
    };
  };
  extensions: {
    cost: {
      requestedQueryCost: number;
      actualQueryCost: number;
      throttleStatus: {
        maximumAvailable: number;
        currentlyAvailable: number;
        restoreRate: number;
      };
    };
  };
}

export const fetchOrders = createAsyncThunk('order/list', async () => {
  const orderId = sessionStorage.getItem('orden') || '';

  if (orderId !== '') {
    try {
      const data = await client('/api/shopify/readdraftorder', {
        body: {
          orderId,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  }
});

export const addOrders = (productId: string) =>
  createAsyncThunk('order/add', async () => {
    const orderId = sessionStorage.getItem('orden') || '';
    if (orderId !== '') {
      try {
        const data = await client('/api/shopify/newdraftorder', {
          body: {
            productId,
            quantity: 1,
          },
        });

        const ID = data.data.draftOrderCreate.draftOrder.id;

        sessionStorage.setItem('orden', ID);
        return data;
      } catch (error) {
        return error;
      }
    } else {
      try {
        const data = await client('/api/shopify/updatedraftorder', {
          body: {
            orderId,
            productId,
            quantity: 1,
          },
        });

        return data;
      } catch (error) {
        return error;
      }
    }
  });

const initialState: ORDERS = {
  data: {
    draftOrder: {
      name: '',
      invoiceUrl: '',
      lineItems: {
        edges: [
          {
            node: {
              id: '',
              name: '',
              originalTotal: 0,
              title: '',
              quantity: 0,
              image: {
                src: '',
              },
            },
          },
        ],
      },
    },
  },
  extensions: {
    cost: {
      requestedQueryCost: 0,
      actualQueryCost: 0,
      throttleStatus: {
        maximumAvailable: 0,
        currentlyAvailable: 0,
        restoreRate: 0,
      },
    },
  },
};

export const orders = createSlice({
  name: 'orders',
  initialState,
  extraReducers: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [fetchOrders.pending]: (state: any) => {
      state.lading = true;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [fetchOrders.fulfilled]: (state: any, action: any) => {
      state.data = action.payload;
      state.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [fetchOrders.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [addOrders.pending]: (state: any) => {
      state.lading = true;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [addOrders.fulfilled]: (state: any, action: any) => {
      state.data = action.payload;
      state.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [addOrders.rejected]: (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  reducers: {},
});

//export const { } = faqs.actions;

export default orders.reducer;
