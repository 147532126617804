import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
interface typeElementsType {
  value: string;
}

const initialState = { value: '' } as typeElementsType;

const typeElements = createSlice({
  name: 'typeElements',
  initialState,
  reducers: {
    setTipoProducto(state, action: PayloadAction<string>) {
      state.value = action.payload;
    },
  },
});

export const { setTipoProducto } = typeElements.actions;
export default typeElements.reducer;
