import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchDiscount = createAsyncThunk('shopify/discount', async () => {
  try {
    const response = await fetch('/api/shopify/getalldiscount');
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
});

const initialState = {
  loading: false,
  data: {
    priceRules: {
      nodes: [],
    },
    extensions: {
      cost: {
        requestedQueryCost: 0,
        actualQueryCost: 0,
        throttleStatus: {
          maximumAvailable: 0,
          currentlyAvailable: 0,
          restoreRate: 0,
        },
      },
    },
  },
};

export const shopifydiscount = createSlice({
  name: 'shopifydiscount',
  initialState,
  extraReducers: {
    [fetchDiscount.pending]: (state) => {
      state.loading = true;
    },
    [fetchDiscount.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchDiscount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  reducers: {},
});

export default shopifydiscount.reducer;
