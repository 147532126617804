// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import cookieCutter from 'cookie-cutter';

const getFormattedDate = () => {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
};

const getDeviceType = () => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        return 'mobile';
    } else if (/iPad|Android|Tablet|Touch/i.test(navigator.userAgent)) {
        return 'tablet';
    } else {
        return 'desktop';
    }
};

const getTypePage = (path) => {
    const rutas = [
        { ruta: '/productos-ibericos', tipo: 'producto' },
        { ruta: '/escuela-del-jamon', tipo: 'post' },
    ];

    const rutaEncontrada = rutas.find((objeto) => path?.includes(objeto.ruta));

    if (rutaEncontrada) {
        return rutaEncontrada.tipo;
    } else {
        return 'home';
    }
};

export const cookies = (
    definition: string,
    url_actual: string,
    url_anterior: string
) => {
    setTimeout(() => {
        const cookieControl = cookieCutter.get('CookieControl');
        if (cookieControl != undefined) {
            const cookies = JSON.parse(cookieControl);

            const optionalCookies = cookies.optionalCookies.behaviouralAdvertising === 'accepted' ? 'granted' : 'denied';
            const analyticsCookies = cookies.optionalCookies.analytics === 'accepted' ? 'granted' : 'denied';
            // console.log(
            //   definition,
            //   cookies.optionalCookies.analytics,
            //   cookies.optionalCookies.behaviouralAdvertising
            // );

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            gtag('consent', 'update', {
                ad_user_data: optionalCookies,
                ad_storage: optionalCookies,
                analytics_storage: analyticsCookies,
                ad_personalization: optionalCookies,
                wait_for_update: 500,
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dataLayer.push({
                event: definition,
                ad_storage: optionalCookies,
                ad_user_data: optionalCookies,
                ad_personalization: optionalCookies,
                page_url: url_actual,
                page_url_referrer: url_anterior,
                page_time_stamp: getFormattedDate(),
                page_template: getTypePage(url_actual),
                user_device: getDeviceType(),
                analytics: analyticsCookies,
                analytics_storage: analyticsCookies,
            });
        }

    }, 300);
};
